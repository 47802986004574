/* CSS Document */


/* 
*
*
[Table of contents]
*
*
*

  color / $white
  dropdown / .dropdown-custom.btn
  navbar / .navbar
  breadcumb / .breadcumb
  home / .subheading
  icon scroll / .icon-scroll-wraper
  footer / footer
  portofolio / AppContainer
  contact / .form-side
  news / .post-content
  create file / .d-create-file
  activity / .filter__l
  feature box / .feature-box .inner
  column nft / .nft_coll
  countdown / .de_countdown
  author list / .author_list
  icon box / .icon-box 
  carousel / .d-carousel
  filter / .items_filter
  colection / #profile_banner 
  item details / .item_info
  wallet / .box-url 
  login / .field-set label
  all icon / .demo-icon-wrap-s2 
  element / .card-header
  media all / @media only screen and (max-width: 1199px)

*/

/*color*/
$white: #fff;
$gray: #bbb;
$black: #111;
$general: #a2a2a2;
$black_soft: #333;
$black_col: #2e333c;
$black_more: #0f0f0f;
$color: #a8a1e5;
$color_more: #a8a1e5;
.greyscheme .col-white{
  color: $white;
}

/*general*/
.greyscheme{
  background: #151515;
}
.greyscheme .br{
  display: block;
  white-space: pre-wrap;
}
.greyscheme strong{
  font-weight: bold;
}
.greyscheme section{
  padding: 90px 0;
  background: #000000;
  &.no-top{
    padding-top: 0 !important;
  }
  &.no-bottom{
    padding-bottom: 0 !important;
  }
  .small-border {
    display: block;
    width: 50px;
    height: 2px;
    background: $color;
    border-left: none;
    border-left-color: currentcolor;
    border-right: none;
    border-right-color: currentcolor;
    margin: 18px auto 30px;
  }
  &.bg-gray{
    background: rgb(247, 244, 253);
  }
}
.greyscheme .container {
    padding-left: 30px;
    padding-right: 30px;
}
.greyscheme .wraper{
  padding: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  .wraperitem{
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
.greyscheme .m-2-hor{
  padding: 0 7%;
  max-width: 1500px;
  margin: 0 auto !important;
}
.greyscheme .m-10-hor{
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  max-width: 1500px;
  margin: 0 auto;
}
.greyscheme .align-items-center {
    align-items: center !important;
}
.greyscheme .text-uppercase {
    text-transform: uppercase !important;
}
.greyscheme .white{
  color: $black;
  background: $white;
}
.greyscheme .black_more{
  background: $black_more;
}
.greyscheme .color{
  color: $color;
}
.greyscheme .btn{
  position: relative;
  overflow: hidden;
  font-size: 15pt;
  color: $white;
  background-color: #F252AA;
  border: #F252AA;
  border-radius: 10;
  padding: 10px 44px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  z-index: 0;
  span{
    color: $white;
    position: relative;
    z-index: 1;
  }
  &::before{
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-image: linear-gradient(to left, $color, $color_more);
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease;
  }
  &:hover::before{
    opacity: 0;
    transition: all 0.3s ease;
  }
  &:hover {
    .shine{
    -webkit-animation: sheen .6s alternate;
    animation: sheen .6s alternate;
    } 
  }
}

.greyscheme .shine {
  content: '';
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: -50px;
  background: -webkit-linear-gradient(top, transparent, rgba(255,255,255,0.2) 5%, transparent);
  background: linear-gradient(to bottom, transparent, rgba(255,255,255,0.2) 5%, transparent);
  -webkit-transform: rotateZ(60deg) translate(-1em, 5.5em);
  transform: rotateZ(60deg) translate(-1em, 5.5em);
}
@-webkit-keyframes sheen {
  0% {
     opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, 6em);
    transform: rotateZ(60deg) translate(0, 6em);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
}

@keyframes sheen {
  0% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, 6em);
    transform: rotateZ(60deg) translate(0, 6em);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
}
.greyscheme .text-gradient {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    background: linear-gradient(to bottom, $color, $color_more);
}
.greyscheme .spacer-10 {
    width: 100%;
    height: 10px;
    display: block;
    clear: both;
}
.greyscheme .de-flex {
    display: flex;
    justify-content: space-between;
}
.greyscheme .de-flex > .de-flex-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.greyscheme hr {
    display: block;
    clear: both;
    border-top: solid 1px #ddd;
    margin: 40px 0 40px 0;
}

/**** heading ***/
.greyscheme h1,
.greyscheme h2,
.greyscheme h4,
.greyscheme h3,
.greyscheme h5,
.greyscheme h6,
.greyscheme .h1_big,
.greyscheme .h1,
.greyscheme .h2,
.greyscheme .h3,
.greyscheme .h4,
.greyscheme .h5,
.greyscheme .h6 {
    margin-top: 0;
    font-weight: 700;
    color: $white;
}
.greyscheme h1, .greyscheme .h1 {
    font-size: 35px;
    margin-bottom: 20px;
    line-height: 1.2em;
    letter-spacing: -1px;
}
.greyscheme h1 .label {
    display: inline-block;
    font-size: 36px;
    padding: 0 6px;
    margin-left: 10px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}
.greyscheme h1.s1 {
    letter-spacing: 30px;
    font-size: 26px;
}
.greyscheme h1 .small-border {
    margin-top: 30px;
}
.greyscheme h1.big,
.greyscheme .h1_big {
    font-size: 64px;
    margin: 0;
    line-height: 70px;
}
.greyscheme h1.very-big {
    font-size: 120px;
    letter-spacing: -5px;
}
.greyscheme h1.ultra-big {
    font-size: 140px;
    line-height: 120px;
    letter-spacing: -6px;
    font-weight: 700;
    margin-bottom: 0;
}
.greyscheme h1.ultra-big span {
    display: inline-block;
}
.greyscheme h1.ultra-big span.underline span {
    display: block;
    border-bottom: solid 12px #fff;
    position: relative;
    margin-top: -5px;
}
.greyscheme h1.very-big-2 {
    font-size: 90px;
    letter-spacing: 25px;
    text-transform: uppercase;
    font-weight: bold;
}
.greyscheme .h2_title {
    font-size: 28px;
    display: block;
    margin-top: 0;
    line-height: 1.2em;
}
.greyscheme h2 .small-border {
    margin-left: 0;
    margin-bottom: 15px;
    width: 40px;
}
.greyscheme h2 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 36px;
    line-height: 46px;
}
.greyscheme h2.style-2 {
    font-size: 30px;
    margin-bottom: 20px;
}
.greyscheme h2.big {
    font-size: 48px;
    line-height: 1.3em;
    margin-bottom: 0;
}
.greyscheme h2 .uptitle {
    display: block;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
    font-weight: 500;
}
.greyscheme h2.s1,
.h2_s1 {
    font-size: 24px;
}
.greyscheme h2.deco-text span {
    font-family: "Parisienne";
    display: block;
    line-height: .85em;
    font-weight: lighter;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
}
.greyscheme h2.deco-text .md {
    font-size: 80px;
}
.greyscheme h2.deco-text .lg {
    font-size: 120px;
}
.greyscheme h2.deco-text .xl {
    font-size: 150px;
}
.greyscheme h3 {
    font-size: 22px;
    margin-bottom: 25px;
}
.greyscheme h4 {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 10px;
}
.greyscheme h4.teaser {
    font-weight: 300;
    font-size: 22px;
}
.greyscheme .subtitle.s2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
}
.greyscheme .subtitle.s2 span {
    margin-right: 20px;
    padding-left: 20px;
}
.greyscheme .subtitle.s2 i {
    margin-right: 10px;
}
.greyscheme .subtitle.s2 span:first-child {
    padding-left: 0;
}
.greyscheme h4.s1 {
    letter-spacing: 10px;
    font-weight: 400;
    font-size: 16px;
}
.greyscheme h4.s2 {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 200;
    line-height: 1.8em;
}
.greyscheme h4.s3 {
    font-family: "Parisienne";
    font-size: 60px;
    font-weight: lighter;
}
.greyscheme h4.s3 {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 15px;
}
.greyscheme .call-to-action h4 {
    text-transform: none;
    font-size: 20px;
}
.greyscheme h1.slogan_big {
    font-weight: 400;
    font-size: 64px;
    line-height: 64px;
    letter-spacing: -2px;
    padding: 0;
    margin: 0px 0 30px 0;
}
.greyscheme h1.title {
    font-size: 64px;
    letter-spacing: 10px;
}
.greyscheme h1.title strong {
    text-transform: none;
    letter-spacing: 0;
    font-weight: normal;
}
.greyscheme h1.hs1 {
    font-family: "Parisienne";
    font-size: 96px;
    display: inline-block;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
}
.greyscheme h1.hs2 {
    font-family: "Parisienne";
    font-size: 72px;
    display: inline-block;
    font-weight: lighter;
}
.greyscheme h2.subtitle {
    margin-top: 0;
}
.greyscheme h2.name {
    color: #fff;
    font-size: 84px;
    line-height: 50px;
}
.greyscheme h2.name span {
    display: block;
    font-size: 32px;
}
.greyscheme h2.name-s1 {
    color: #fff;
    font-size: 84px;
    font-weight: 700;
    line-height: 50px;
}
.greyscheme h2.name-s1 span {
    display: block;
    font-size: 32px;
}
.greyscheme h2.hw {
    display: block;
    font-family: "Parisienne";
    font-size: 48px;
    text-transform: none;
    font-weight: lighter;
}
.greyscheme h2.deco {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
}
.greyscheme h2.deco span {
    display: inline-block;
    position: relative;
}
.greyscheme h2.deco span:before,
.greyscheme h2.deco span:after {
    content: "";
    position: absolute;
    border-top: 1px solid #bbb;
    top: 10px;
    width: 100px;
}
.greyscheme h2.deco span:before {
    right: 100%;
    margin-right: 15px;
}
.greyscheme h2.deco span:after {
    left: 100%;
    margin-left: 15px;
}
.greyscheme h2.hs1 {
    font-size: 40px;
}
.greyscheme h2.hs1 i {
    font-size: 48px;
    position: relative;
    top: 10px;
    color: #ff0042;
    margin: 0 10px 0 10px;
}
.greyscheme h2.hs1 span {
    font-size: 48px;
    position: relative;
    top: 10px;
    font-family: "Miama";
    margin: 0 15px 0 10px;
    font-weight: normal;
}
.greyscheme h2 .de_light .text-light h2 {
    color: #fff;
}
.greyscheme .text-light h2.deco span:before,
.greyscheme .text-light h2.deco span:after {
    border-top: 1px solid rgba(255, 255, 255, .5);
}
.greyscheme h2.s2 {
    font-weight: 400;
}
.greyscheme h2.s3 {
    font-size: 36px;
    margin-bottom: 20px;
}
.greyscheme h4.title {
    border-bottom: solid 1px #ddd;
    margin-bottom: 20px;
}
.greyscheme h4.style-2 {
    font-size: 18px;
}
.greyscheme h4.title {
    border-bottom: solid 1px #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.greyscheme h5.s2 {
    letter-spacing: 1px;
    font-size: 14px;
}
.greyscheme h5 {
    font-size: 18px;
}
.greyscheme h4.s1 {
    font-size: 12px;
    letter-spacing: 20px;
    text-transform: uppercase;
    font-weight: 500;
}
.greyscheme p.lead {
    font-size: 18px;
    line-height: 1.7em;
    margin-top: 0;
    font-weight: 400;
}
.greyscheme .btn-main {
    display: block;
    width: max-content;
    text-align: center;
    color: #fff !important;
    background: #F252AA;
    border-radius: 10px;
    letter-spacing: normal;
    outline: 0;
    font-weight: 800;
    text-decoration: none;
    padding: 8px 40px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    box-shadow: 2px 2px 20px 0px rgba(131, 100, 226, 0);
    transition: all 0.3s ease;
    &:hover{
      box-shadow: 2px 2px 20px 0px #F252AA;
      transition: all 0.3s ease;
    }
    &.inline{
      display: inline-block;
      margin-right: 15px;
      &.white{
        color: $color_more !important;
        background:$white;
      }
    }
}

.greyscheme .imgslickz{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.greyscheme .centered {
    display: flex;
    align-items: center;
    justify-content: center;
}
/*** slider ***/
.greyscheme .jumbomain{
  position: relative;
  padding: 0;
  margin: 0;
}
.greyscheme .slider-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;
  .previousButton, .nextButton {
    opacity: 0;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  &:hover{
    .previousButton, .nextButton {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
}
.greyscheme .slide{
  background-size: cover !important;
}
.greyscheme .slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.1);
    bottom: 0;
    left: 0;
}
.greyscheme .previousButton, .greyscheme .nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    text-align: center;
    svg{
      display: none;
    }
}
.greyscheme .previousButton::before, .greyscheme .nextButton::before{
    content:"\f054";
    font-family: FontAwesome;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: normal;
    color: #fff;
    opacity: .8;
}
.greyscheme .previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
          transform: rotate(180deg) translateY(calc(50% + 0px));
}
.greyscheme .previousButton{
  left: 4%;
}
.greyscheme .nextButton {
  right: 4%;
}
.greyscheme .slider-content {
  text-align: left;
}
.greyscheme .slider-content .inner {
  padding: 0 12%;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.greyscheme .slider-content .inner button{
  font-weight: 500;
  position: relative;
  overflow: hidden;
  font-size: 11pt;
  letter-spacing: 1px;
  color: $white;
  border: 2px solid $color;
  background: none ;
  border-radius: 0;
  padding: 0 44px;
  height: 45px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  z-index: 0;
  span{
    position: relative;
    z-index: 1;
  }
  &::before{
    content: '';
    width: 180%;
    height: 180%;
    top: -100%;
    left: -100%;
    position: absolute;
    background-image: linear-gradient(to left, $color, $color_more);
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease;
  }
  &:hover::before{
    top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.3s ease;
  }
  &:hover {
    .shine{
    -webkit-animation: sheen .6s alternate;
    animation: sheen .6s alternate;
    } 
  }
}
.greyscheme .slider-content .inner h1 {
  font-weight: 600;
  max-width: 840px;
  color: #fff;
  font-size: 53px;
}
.greyscheme .slider-content .inner p {
  color: #fff;
  font-size: 40px;
  line-height: 1.3;
  max-width: 640px;
  margin-bottom: 30px;
}

.greyscheme .slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.greyscheme .slider-content section span {
  color: #fff;
}
.greyscheme .slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}
.greyscheme .slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}
.greyscheme .slider-content section span strong {
  color: #fff;
  font-size: 14px;
  display: block;
}
@media (max-height: 500px) {
  .slider-wrapper, .slide {
    height: calc(100vh - 75px);
  }    
}
@media (max-width: 640px) {
  .slider-wrapper, .slide {
    height: calc(80vh - 75px);
  }    
}
@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }    
}
@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
  .slider-content .inner p{
    font-size: 21px;
  }
}
.greyscheme .slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  opacity: 0;
}
.greyscheme .slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  opacity: 0;
}
.greyscheme .slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  opacity: 0;
}
.greyscheme .slide section * {
  transition: all 0.3s ease;
}
.greyscheme .slide section img {
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
  opacity: 0;
}
.greyscheme .slide section span {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  opacity: 0;
}
.greyscheme .slide section span strong {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  opacity: 0;
}






/*** footer ***/
.greyscheme footer{
  padding: 80px 0 0 0;
  background: rgba(255, 255, 255, .025) !important;
  &.footer-light {
      border-top: solid 1px rgba(255,255,255,.025);
      color: #a2a2a2;
      a {
        color: #a2a2a2;
        font-weight: 400;
        text-decoration: none !important;
        &:hover{
          color: $color;
        }
    }
    #form_subscribe{
       input[type="text"] {
        width: 80%;
        float: left;
        border-radius: 30px 0 0 30px;
        color: #ffffff;
        background: rgba(255, 255, 255, .1);
        border: solid 1px #333333;
        border-right-color: rgb(51, 51, 51);
        border-right-style: solid;
        border-right-width: 1px;
        border-right: none;
        padding: 6px 12px;
        margin-bottom: 0;
        box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
      }
      #btn-subscribe i {
        text-align: center;
        font-size: 28px;
        float: left;
        width: 20%;
        background: $color;
        color: #ffffff;
        display: table-cell;
        padding: 5px 0 5px 0;
        border-radius: 0 30px 30px 0;
      }
    }
    .subfooter {
      margin-top: 40px;
      padding: 20px 0 20px 0;
      border-top: solid 1px rgba(255,255,255,.1);
      span{
        cursor: pointer;
        img{
          margin-right: 30px;
          &.f-logo.d-1{
            display: inline-block;
          }
          &.f-logo.d-3{
            display: none;
          }
          &.f-logo.d-4{
            display: none;
          }
        }
        &.copy{
          cursor: default;
          margin: 0;
        }
      }
      .social-icons {
        display: inline-block;
        span {
          color: #595d69;
          i {
            text-shadow: none;
            color: $white;
            background: #212428;
            padding: 12px 10px 8px 10px;
            width: 34px;
            height: 34px;
            text-align: center;
            font-size: 16px;
            border-radius: 5px;
            margin: 0 5px 0 5px;
            transition: .3s;
          }
          &:hover i{
            color: $black;
            background: $white;
            transition: .3s;
          }
        }
      }
    }
  }
  .widget {
    margin-bottom: 30px;
    padding-bottom: 30px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li{
        margin: 5px 0;
      }
    }
    h5 {
      margin-bottom: 20px;
    }
  }
}

.greyscheme #scroll-to-top {
    cursor: pointer;
    width: max-content;
    height: max-content;
    position: fixed;
    right: 10px;
    z-index: 999;
    &.init{
      bottom: -60px;
      transition: all 0.3s ease;
    }
    &.show{
      bottom: 15px;
      transition: all 0.3s ease;
    }
    div{
      font-size: .8rem;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border-radius: 30px;
      background: $color;
      transition: all 0.3s ease;
      &:hover{
        transform: scale(1.1);
        transition: all 0.3s ease;
      }
      i{
        font-style: normal;
        &:before{
        font-family: "FontAwesome";
        font-size: 18px;
        content: "\f106";
        color: #fff !important;
        position: relative;
        }
      }
    }
}









/*** media all ***/
@media only screen and (max-width: 1199px) {
  .greyscheme .item-dropdown{
    position: relative;
    width: 100%;
    inset: unset;
    padding-top: 10px;
    border: 0;
    background: none;
    box-shadow: unset;
    .dropdown{
      a{
        color: $white !important;
        padding: 10px 10px 10px 0px;
        width: 100%;
        text-align: left;
        left: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
  .greyscheme .navbar{
    top: 0;
    &.white a{
      color: $white;
    }
  }
  .greyscheme #myHeader .container {
      max-width: unset !important;
  }
}
@media only screen and (max-width: 1024px) {
  .greyscheme  .m-10-hor {
    padding-left: 2%;
    padding-right: 2%;
  }
  .greyscheme  .subfooter .de-flex, .subfooter .de-flex-col {
    display: block;
  }
  footer.footer-light .subfooter span.copy{
    display: block;
    margin: 20px 0;
  }
}
@media only screen and (max-width: 768px) {
  .greyscheme .breadcumb .mainbreadcumb .list{
    justify-content: left;
    text-align: left;
    margin-bottom: 30px;
  }
  .greyscheme .previousButton, .greyscheme  .nextButton {
    opacity: 1 !important;
  }
  .greyscheme .xs-hide {
        display: none;
  }
  .greyscheme .container {
        padding-left: 30px;
        padding-right: 30px;
  }
  .greyscheme .author_list {
    column-count: 1;
    column-gap: 20px;
  }
  .greyscheme #form_quick_search{
    top: 0;
    width: 100%;
    .col{
      padding-right: 0;
    }
  }
  .greyscheme #form_quick_search input[type="text"]{
    width: 80%;
  }
  .greyscheme  #form_quick_search #btn-submit{
    width: 20%;
  }
  .greyscheme #form_quick_search #btn-submit i{
    width: 100%;
  }
  .greyscheme .items_filter .dropdownSelect{
    margin-right: 0;
    width: 96%;
  }
  .greyscheme .item_info{
    margin-top: 30px;
  }
  .greyscheme .profile_avatar {
    display: block;
  }
  .greyscheme .d_profile {
    display: block;
   .de-flex-col {
    display: block;
  }
  }
  .greyscheme .profile_name {
    margin-left: 0;
    margin-top: 20px;
    display: block;
    width: 100%;
  }
  .greyscheme .profile_follow {
    margin-top: 50px;
    width: 100%;
  }
  .greyscheme .nft__item{
    margin: 0 0 30px 0;
  }
  .greyscheme .de_nav.text-left{
    margin-left: 0;
  }
}
@media only screen and (max-width: 600px) {

}
@media only screen and (max-width: 420px) {

}
@media only screen and (max-width: 378px) {

}

