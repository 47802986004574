@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url("https://use.typekit.net/gor4ued.css");

:root {
	--clr-primary: #0F172A;
	--clr-secondary: #0F172A;
	/* --clr-secondary: #2c2b2c; */

	--clr-neutral: #949494;

	--clr-white: #F1F2F9;
	--clr-blue: #2187D0;
	--clr-red: #F45353;
	--clr-green: #25CE8F;
	--bs-body-color:#F1F2F9;
	--bs-body-bg:#0F172A
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

/* ------------------------------------------------------ */

html {
	font-family: "DM Sans";
	color: var(--clr-white);
}

main {
	min-height: 100vh;
}

/* ------------------------------------------------------ */
/* -- Text -- */

h1 {
	font-size: clamp(1rem, 2vw, 1.25rem);
	font-weight: 500;
}

h2 {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;

	margin: 0.375em 0;

	font-weight: 500;
	font-size: clamp(0.95rem, 2vw, 1.10rem);
}

p,
small {
	font-weight: 400;
}

small {
	color: var(--clr-neutral);
}

/* ------------------------------------------------------ */
/* -- Display -- */

.flex {
	display: flex;
	align-items: center;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	padding-top: 30px;
	margin: 0 auto;

	font-size: clamp(1rem, 2vw, 1.25rem);
	font-weight: 500;
}

.flex-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flex-end {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.flex-1 {
	flex: 1;
}

.grid {
	display: grid;
	grid-template-columns: repeat(12, 1fr);

}

/* ------------------------------------------------------ */

.exchange__section--left {
	background: var(--clr-secondary);
	padding: 2em;

	/* Grid Setup */
	grid-column-start: 1;
	grid-column-end: 13;
}

.exchange__section--right {
	background: var(--clr-primary);



	/* Grid Setup */

}

.stakingPetalFlower{
	align-items: center;
	display:flex;
	justify-content: center;

}

.PetalMint{
	background-color: #a8a1e5;
	/* width: flex;
	height:flex; */
	border-radius:10px;
	margin:2em 0 1em 0;
	color:black;
	padding:10px;

}
.test123{
	justify-items: center;
	align-items: center;
}
.etherClaim{
	grid-column: auto;
	width: flex;
	height:flex;
	border-radius:10px;
	margin:2em 0 0 0;
	padding:10px;
}
.PolynClaim{
	background-color: #a8a1e5;
	width: flex;
	height:flex;
	border-radius:10px;
	margin:2em 0 0 0;
	color:black;
	padding:10px;
}
.PetalText{
	align-items: center;
	margin: 20px;
}

.stakingDapp {
	/* grid-column-start: 1;
	grid-column-end: 13; */
	background-color: #2187D0;
	padding: 1em 10em 25em; /* Simplified padding shorthand */
	background-color: var(--clr-primary);

}

.claimEther {
	/* grid-column-start: 1;
	grid-column-end: 13; */
	display: flex;
	justify-content: center; /* Center horizontally */
	align-items: center; /* Center vertically */
	padding: 0em 2em 0em 0em; /* Simplified padding shorthand */
}
.test1{
	grid-column-start: 2;
	grid-column-end: 12;
	align-items: center;
}
.claimEther2{
	/* grid-column-start: 1;
	grid-column-end: 13; */
	display: flex;
	justify-content: center; /* Center horizontally */
	align-items: center; /* Center vertically */
	padding: 1em 2em 25em; /* Simplified padding shorthand */
	background-color: var(--clr-primary);

}

.claimBackground{
	background-color: rgba(255, 255, 255, 0.121);
	backdrop-filter: blur(10px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.2);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 18px;
	font-weight: bold;
	position: relative;
	overflow: hidden;

	width: 80em;
	padding:1em 0 2em 0;
	width: 50em;
	margin:3em;
}


.claimBackground::before {
	content: "";
	position: absolute;
	top: -2px;
	left: -2px;
	right: -2px;
	bottom: -2px;
	border-radius: inherit;
	background: linear-gradient(
	  45deg,
	  rgba(113, 70, 244, 0.728),
	  rgba(27, 68, 129, 0.45),
	  rgba(113, 70, 244, 0.728)
	);
	z-index: -1;
  }

/* ------------------------------------------------------ */
/* Exchange Header */




/*----------------------------------------------------------*/
/*emailcaptureform */
.captureheading{
	color: black;

}
.email-form {
	max-width: 300px;
	margin: 0 auto;
  }

  .email-form label {
	font-weight: bold;
  }

  .email-form input[type="email"] {
	border-radius: 0;
  }

  .email-form button[type="submit"] {
	margin-top: 10px;
  }

.emailForm{
	background-color: #a8a1e5;
	width:fit-content;
	border-radius: 10px;
	padding:20px;
	color:black;
}

.claimSwitch{
	margin:3.5em 0 0 0;
}













.exchange__header {
	background: var(--clr-primary);
	height: 10vh;

	position: relative;
}

.exchange__header small {
	margin: 0 0.50em 0 0;
}

.exchange__header select,
.exchange__chart select {
	background: transparent;
	color: var(--clr-white);
	border: none;

	font-weight: 700;

	cursor: pointer;
}

.exchange__header select:focus {
	background: var(--clr-secondary);
}

.exchange__header--brand {
	padding: 0.75em 2em;

	grid-column-start: 1;
	grid-column-end: 6;
}

.exchange__header--brand h1 {
	display: none;
	font-family:  "poppins", sans-serif;
	font-weight: 600;
	font-style: normal;
}

.exchange__header--networks {
	grid-column-start: 12;
	grid-column-end: 13;

	grid-row: 2;

	position: absolute;
	top: 24px;
	right: 64px;
	transform: translate(0, -50%);
}

.exchange__header--account {
	background: var(--clr-secondary);

	position: absolute;
	top: 50%;
	right: 24px;
	transform: translate(0, -50%);

	width: 400px;
	height: 48px;

	border-radius: 10px;

	grid-column-start: 9;
	grid-column-end: 13;
}

.exchange__header--account p {
	margin: 0 auto;
}

.routerSwitcherNFT {

	position: relative;
    display: flex;
    align-items: center;
    color: var(--colors-secondary);
    font-size: 25px;
    font-weight: 600;
    opacity: 1;
    padding: 0px 16px;
    height: 48px;
	text-decoration: none;
	grid-column-start: 6;
	grid-column-end: 7;
	grid-row: 1;

	position: absolute;
	top: 50%;
	left: 24px;
	transform: translate(0, -50%);
}

.routerSwitcherStake{

	position: relative;
    display: flex;
    align-items: center;
    color: var(--colors-secondary);
    font-size: 25px;
    font-weight: 600;
    opacity: 1;
    padding: 0px 16px;
    height: 48px;

	grid-column-start: 9;
	grid-column-end: 7;
	grid-row: 1;

	position: absolute;
	top: 50%;
	left: 24px;
	transform: translate(0, -50%);
}



.exchange__header--account a,
.exchange__header--account button {
	display: flex;
	justify-content: center;
	align-items: center;

	background: #a8a1e5;
	border: none;
	color: #222326;

	margin: 0;
	border-radius: 10px;

	width: 182px;
	height: 48px;

	font-size: 16px;
	text-decoration: none;

	transition: all 250ms ease;
}

.exchange__header--account button:hover {
	background: #2c2b2c;
	border: none;
}

.exchange__header--account img {
	width: 32px;
	height: auto;
	margin: 0 0 0 0.75em;
}

/* ------------------------------------------------------ */
/* Universal Component */

.component {
	position: relative;
}

.component__header {
	margin: 0 0 0.75em 0;
}
.aasf1122{
	display:flex;
	justify-content: center;
	min-height: 100px;
}
/* ------------------------------------------------------ */
/* MARKET COMPONENT */

.exchange__markets,
.exchange__transfers,
.exchange__orders {
	grid-column-start: 2;
	grid-column-end: 12;
	align-items: center;
}
.centerRow{
	justify-content: center;
	align-items: center;
}
.carousel{
display:flex;
justify-content: center;
align-items: center;
padding:5em;
margin: -2.5em 0 0 0;
}

.test {
	grid-column-start: 10;
	grid-column-end: 1;
}

.exchange__markets select {
	background: var(--clr-primary);
	color: var(--clr-text);
	border: none;

	width: 100%;
	padding: 1em;

	font-weight: 700;

	cursor: pointer;

	appearance: none;
	position: relative;
}
@keyframes flash2 {
    0% { box-shadow: 0 0 2px #397ff5; }
    50% { box-shadow: 0 0 10px #397ff5; }
    100% { box-shadow: 0 0 2px #F252AA; }
  }
@keyframes flash {
    0% { box-shadow: 0 0 5px #F252AA; }
    50% { box-shadow: 0 0 20px #F252AA; }
    100% { box-shadow: 0 0 5px #F252AA; }
  }

  .containerEthinDapp {
    display: flex;
    justify-content: right;
    align-items: right;
	padding:1px 20px;

  }

.flash-button {
	/* animation: flash 7s infinite;
    display: inline-block;
    padding: 10px 15px;
    background:#F252AA;
    border: none;
    color: #ffffff;
    font-size: 16.5px;
    cursor: pointer;
    border-radius: 8px;
	 */
	 background-color: rgba(255, 255, 255, 0.121);
	backdrop-filter: blur(10px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.2);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 18px;
	font-weight: bold;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
	position: relative;
	padding:0.5em;
	overflow:hidden;
	min-width: 5em;
	max-width: 10em;
	min-height: 2em;
	max-height: 10em;
	

}

.flash-button::before {
	content: "";
	position: absolute;
	top: -100px;
	left: -2px;
	right: -2px;
	bottom: -2px;
	border-radius: inherit;
	background: linear-gradient(
	  45deg,
	  rgba(50, 44, 240, 0.356),
	  rgba(153, 3, 253, 0.144)
	);
	z-index: -1;
  }

.flash-buttonHome {
	animation: flash 7s infinite;
    display: inline-block;
    padding: 10px 15px;
    background:#F252AA;
    border: none;
    color: #ffffff;
    font-size: 16.5px;
    cursor: pointer;
    border-radius: 8px;


	display: block;
    width: max-content;
    text-align: center;
    color: #fff !important;
    background: #F252AA;
    border: none;
    cursor: pointer;
    box-shadow: 2px 2px 20px 0px rgba(131, 100, 226, 0);
    transition: all 0.3s ease;
}
.flash-buttonHome:hover {
  transform: translateY(-5px);
}

.greyscheme{
	.btn{
		background-color:black;
	}
}
.claimPage{
	align-content: center;
	margin: 5em 5em 0 5em;
}
.NavMenu{
	display:flex;
	flex-direction: row;
	justify-content: center;
}

.NavItems{
	padding:30px;
	position: relative;
	padding: 10px 20px;
	color: #fff;
	border: none;
	cursor: pointer;
	font-size: 16px;
	overflow: hidden;
}

a {
	color:white;
}

  .NavItems:hover {
	color: #fefefe;
	border-radius: 10px;
	background:#192337;
	/* background: linear-gradient(rgb(40, 174, 228), rgb(239, 84, 158)); */
 }

.claimInfo{
	background-color: #a8a1e5;
	/* width: flex;
	height:flex; */
	border-radius:10px;
	margin:0em 0 0em 0;
	color:black;
	padding:10px;
	align-items: center;
	justify-content: center;
	align-content: center;
}
.ethLogoClaim{
	align-items: center;
	justify-content: center;
	align-content: center;
	margin: 3em 0 0 0;
}






.petalimgContainer{
	align-items: center;
	display:flex;
	justify-content: center;

}

.justifyContainer{
	align-items:center;
}
.petalVideo{
	height:300px;
	/* padding:1em; */
	border-radius:2em 2em 2em 2em;
}


.accordion-item{
	border:transparent;
	background-color: #a8a1e5;
	color:black;
	width: 50%;
	align-items: center;
	margin:auto;
}

.accordion-button{
	background-color:#0f1d3f;
}

.form-control1{
	background-color:white;
}
/* ------------------------------------------------------ */
/* TRANSFERS COMPONENT */

.exchange__transfers img {
	float: left;
	margin: 0 0.30em 0 0;
}

.exchange__transfers--form .flex-between {
	padding: 0 10em 0.em 0;
}

/* ------------------------------------------------------ */
/* CHART COMPONENT */

.exchange__chart {
	background: var(--clr-secondary);

	padding: 0.75em 1.75em;
	margin: 0.75em;
	min-height: 325px;

	grid-column-start: 1;
	grid-column-end: 13;
}

.apexcharts-canvas {
	margin: 0 auto;
}

.apexcharts-tooltip {
	background: var(--clr-primary);
	color: var(--clr-white);
}

/* ------------------------------------------------------ */
/* TRANSACTIONS COMPONENT */

.exchange__transactions {
	background: var(--clr-secondary);

	padding: 0.75em 1.75em;
	margin: 0.75em;

	grid-column-start: 1;
	grid-column-end: 13;

	height: 215px;
	overflow-y: scroll;
	scrollbar-width: thin;
}

.exchange__transactions::-webkit-scrollbar,
.exchange__trades::-webkit-scrollbar {
	background: var(--clr-blue);
	width: 4px;
}

.exchange__transactions::-webkit-scrollbar-thumb,
.exchange__trades::-webkit-scrollbar-thumb {
	background: var(--clr-white);
	border-radius: 15px;
}

/* ------------------------------------------------------ */
/* TRADES COMPONENT */

.exchange__trades {
	background: var(--clr-secondary);

	padding: 0.75em 1.75em;
	margin: 0.75em;

	grid-column-start: 1;
	grid-column-end: 13;

	height: 215px;
	overflow-y: scroll;
	scrollbar-width: thin;
}

/* ------------------------------------------------------ */
/* ORDERBOOK COMPONENT */

.exchange__orderbook {
	background: var(--clr-secondary);

	padding: 0.75em 1.75em;
	margin: 0.75em;

	grid-column-start: 1;
	grid-column-end: 13;
}

.exchange__orderbook--sell tbody tr:hover,
.exchange__orderbook--buy tbody tr:hover {
	background: var(--clr-primary);
	cursor: pointer;
}

/* ------------------------------------------------------ */
/* Images */

.logo {
	width: 75px;
	height: auto;
	margin: 0 0.5em 0 0;
}

.staking {
	width: 20px;
	height: auto;
	margin: 0 0.5em 0 0;
}

.identicon {
	margin: 0 0 0 0.60em;
}

/* ------------------------------------------------------ */
/* Misc */

hr {
	background: #2E3950;

	width: 100%;
	height: 1px;
	margin: 1.75em auto;

	border: none;
}

.hr--vertical {
	flex: 1;
	background: #2E3950;

	margin: 0 auto;
	width: 1px;
	height: 100%;
}

.divider {
	padding: 0 15px;
}

/* ------------------------------------------------------ */
/* HTML COMPONENTS */
/* -- Button -- */

.button,
.button--sm {
	background: transparent;
	color: #a8a1e5;

	border: 1.5px solid #a8a1e5;
	border-radius: 10px;

	width: 100%;
	margin: 0.75em auto;
	padding: 1.15em;

	font-weight: 700;

	cursor: pointer;
	position: relative;

	transition: all 250ms ease;
}





.big{
	background-color: #2187D0;
}
.mint{
color: rgb(255, 255, 255);
}
.stakingbox{
	grid-column-start: 5;
		grid-column-end: 10;
		margin: 2em 0 0 0;

}
.staking-Background{
	background-color: rgba(255, 255, 255, 0.121);
	backdrop-filter: blur(10px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.2);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 18px;
	font-weight: bold;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
	position: relative;
	overflow: hidden;
}
.staking-Background::before {
	content: "";
	position: absolute;
	top: -2px;
	left: -2px;
	right: -2px;
	bottom: -2px;
	border-radius: inherit;
	background: linear-gradient(
	  45deg,
	  rgba(195, 3, 253, 0.401),
	  rgba(90, 115, 153, 0.5),
	  rgba(195, 3, 253, 0.464)
	);
	z-index: -1;
  }
  @keyframes borderAnimation {
	0% {
	  transform: translate(0, 0);
	}
	25% {
	  transform: translate(100%, 0);
	}
	50% {
	  transform: translate(100%, 100%);
	}
	75% {
	  transform: translate(0, 100%);
	}
	100% {
	  transform: translate(0, 0);
	}
  }
  .staking-Background::after {
	content: "";
	position: absolute;
	top: -2px;
	left: -2px;
	right: -2px;
	bottom: -2px;
	border-radius: inherit;
	background: linear-gradient(
	  45deg,
	  rgba(76, 129, 253, 0.5),
	  rgba(7, 23, 61, 0.5),
	  rgba(76, 129, 253, 0.5)
	);
	z-index: -1;
	animation-duration: 5s;
  }

  .switcher{
	margin: 0 0 1em 9em;
	align-items: center;
	align-content: center;
	justify-content: center;
  }





.petalbutton,
.petalbutton--sm {
	background: transparent;
	color: #401791;

	border: 1.5px solid #724dbd;
	border-radius: 10px;

	width:  100%;

	margin: 0 0 2em 6em;
	padding: 1.5em;

	font-weight: 700;

	cursor: pointer;
	position: relative;

	transition: all 250ms ease;
	white-space: nowrap;

	animation: flash2 2s infinite;
    display: inline-block;
    padding: 10px 15px;
    background:#F252AA;
    border: none;
    color: #ffffff;
    font-size: 16.5px;
    cursor: pointer;
    border-radius: 8px;


}
.petalMintButton {
	background: transparent;
	color: #401791;
	/* background: #397ff5; */
	background: linear-gradient(
	  90deg,
	  #8943ea,
	  #2092e4 
	);
	color: var(--clr-text);
	border: transparent;
	border-radius: 10px;

	width:  100%;

	margin: 0 0 2em 0em;
	padding: 1.5em;

	font-weight: 700;

	cursor: pointer;
	position: relative;

	transition: all 250ms ease;
	white-space: nowrap;


}

.petalMintButton:hover {
	background-color: #F252AA;
  transform: translateY(-5px);
}

.claimEtherB{
	background: transparent;
	color: #401791;
	/* background: #397ff5; */
	background: linear-gradient(
	  90deg,
	  #8943ea,
	  #2092e4 
	);
	color: var(--clr-text);
	border: transparent;
	border-radius: 10px;

	width:  100%;

	margin: 0 0 2em 0em;
	padding: 1.5em;

	font-weight: 700;

	cursor: pointer;
	position: relative;

	transition: all 250ms ease;
	white-space: nowrap;
}
.claimEtherB:hover {
	background-color: #F252AA;
  transform: translateY(-5px);
}
.stakingButton {
	background: transparent;
	color: #401791;
	background: linear-gradient(
	  90deg,
	  #8943ea,
	  #2092e4 
	);
	color: var(--clr-text);
	border: transparent;
	border-radius: 10px;

	width:  100%;

	margin: 0 0 1em 0;
	padding: 1.5em;

	font-weight: 700;

	cursor: pointer;

	transition: all 250ms ease;
	white-space: nowrap;
}

.stakingButton:hover {
	background-color: #F252AA;
  transform: translateY(-5px);
}

.button--sm {
	width: 50%;

	margin: 0 auto;
	padding: 0.25em;

	border-radius: 5px;
}

.button span {
	position: relative;
}
.PetalFlex{
display:flex;
justify-content: center;
align-items: center;
padding:em;
}
.button span::after {
	content: '';

	/* background-image: url('./assets/right-arrow-blue.svg'); */
	background-size: contain;
	background-repeat: no-repeat;

	position: absolute;
	top: 50%;
	right: -14px;
	transform: translate(50%, -50%);

	width: 10px;
	height: 12px;

	transition: all 250ms ease;
}

.button:hover,
.button--sm:hover {
	color: #724dbd;
	border: 1.5px solid var(--clr-white);
}

.button:hover span::after {
	background-image: url('./assets/right-arrow-white.svg');
	right: -18px;
}


.button--filled {
	background: #724dbd;
	color: #724dbd;
	border: 1px solid transparent;
}

.button--filled span::after {
	background-image: url('./assets/right-arrow-white.svg');
}

.button--filled:hover {
	border: 1px solid transparent;
}


.petalbutton--filled {
	background: #397ff5;
	color: var(--clr-text);
	border: 1px solid transparent;
}

.petalbutton--filled2 {
	background: #397ff5;
	color: var(--clr-text);
	border: 1px solid transparent;
}

.petalbutton--filled:hover {
	background-color: #F252AA;
  transform: translateY(-5px);
}
.petalbutton--filled2:hover {
	background-color: #F252AA;
  transform: translateY(-5px);
}
.petalbutton--filled span::after {
	background-image: url('./assets/right-arrow-white.svg');
}

@keyframes pulse {
	0% {
	  box-shadow: 0 0 0 0 rgba(255, 199, 199, 0.7);
	}
	70% {
	  box-shadow: 0 0 0 35px rgba(18, 66, 82, 0);
	}
	100% {
	  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
  }

  .pulse {
	animation: pulse .1s infinite;
  }

/* .petalbutton--filled:hover {
	border: 1px solid transparent;
} */
.Flower_Mint{
	position:relative;
	right:-250px;
}
/* ------------------------------------------------------ */
/* -- Input -- */
.petalinput{
	background: #a8a1e5;
	color: black;

	border: none;
	border-radius: 10px;

	width: 100%;
	margin: 0.75em auto;
	padding: 1.15em;

	font-family: "DM Sans";
	font-weight: 400;

	outline: none;
	position: relative;
}
input {
	background: #a8a1e5;
	color: black;

	border: none;
	border-radius: 10px;

	width: 100%;
	margin: 0.75em auto;
	padding: 1.15em;

	font-family: "DM Sans";
	font-weight: 400;

	outline: none;
	position: relative;
}

input::placeholder {
	color: #7e7e7e;
}

/* ------------------------------------------------------ */
/* -- Tabs & Tab -- */

.tabs {
	background: #2c2b2c;
	border-radius: 10px;
	padding: 0.20em;
}

.tab {
	background: transparent;
	color: #ffffff;

	min-width: 6em;

	padding: 0.50em 0.75em;

	border: none;
	border-radius: 8px;

	font-family: "DM Sans";
	font-weight: 500;

	cursor: pointer;

	position: relative;

	/* transition: all 250ms ease; */
}
.additionalInfo{
	background-color: #030111;
	width: 400px;
	height: 200px;
	border-radius: 10px;
}
.tab--active {
	background: #F252AA;
}

.tab--active2 {
	background: #a8a1e5;
}

.tabPetal {
	background: transparent;
	color: #ffffff;

	min-width: 6em;

	padding: 0.50em 0.75em;

	border: none;
	border-radius: 8px;

	font-family: "DM Sans";
	font-weight: 500;

	cursor: pointer;

	position: relative;

	/* transition: all 250ms ease; */
}

/* .switcher1--active {
	background: #a8a1e5;
}

.switcher1 {
	background: transparent;
	color: #ffffff;

	min-width: 6em;

	padding: 0.50em 0.75em;

	border: none;
	border-radius: 8px;

	font-family: "DM Sans";
	font-weight: 1000;

	cursor: pointer;

	position: relative;

	

 */


.additionalInfoPetal{
	background-color: #030111;
	width: 400px;
	height: 200px;
	border-radius: 10px;
}
.tab--activePetal {
	background: #F252AA;
}





/* ------------------------------------------------------ */
/* -- Table -- */

table {
	table-layout: fixed;
	width: 100%;
}

table caption {
	text-align: left;
	margin: 0 0 0.50em;
}

table th {
	color: var(--clr-neutral);

	margin: 0.50em 0;

	font-size: 0.85em;
	font-weight: 500;
	text-align: right;
}

table th img {
	float: right;
}

table th:first-child,
td:first-child {
	text-align: left;
	display: flex;
}

table td {
	min-width: max-content;

	margin: 0.25em 0;

	font-size: clamp(0.90rem, 2vw, 0.95rem);
	font-weight: 400;
	text-align: right;
}

/* ------------------------------------------------------ */
/* -- Alert -- */

.alert {
	background: #222D41;

	height: 100px;
	width: 300px;

	position: fixed;
	bottom: 1.50em;
	right: 1.50em;
	z-index: 1;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	text-align: center;

	cursor: pointer;

	transition: all 500ms ease;
}

.alert a {
	color: var(--clr-blue);
	text-decoration: none;
	margin: 0.25em;
}

.alert a:hover {
	color: var(--clr-white);
}

.alert--remove {
	opacity: 0;
	pointer-events: none;
}



/* ------------------------------------------------------ */
/* -- Banner -- */

.banner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 0 auto;
}

/* ------------------------------------------------------ */
/* Media Queries */

@media screen and (min-width: 576px) {

	.exchange__markets,
	.exchange__transfers,
	.exchange__orders {
		grid-column-start: 3;
		grid-column-end: 11;
	}

	.exchange__header--brand h1 {
		display: contents;
	}
}

@media screen and (min-width: 768px) {
	.exchange__header--brand {
		grid-column-start: 1;
		grid-column-end: 4;
	}

	.exchange__header--networks {
		grid-column-start: 4;
		grid-column-end: 6;
		grid-row: 1;

		position: absolute;
		top: 50%;
		left: 24px;
		transform: translate(0, -50%);
	}

	.exchange__markets {
		grid-column-start: 1;
		grid-column-end: 6;

		grid-row-start: 1;
		grid-row-end: 3;
	}

	.exchange__orders {
		grid-column-start: 1;
		grid-column-end: 6;

		grid-row-start: 2;
		grid-row-end: 3;
	}

	.exchange__transfers {
		grid-column-start: 7;
		grid-column-end: 13;

		grid-row-start: 1;
		grid-row-end: 3;
	}

	.exchange__transactions {
		grid-column-start: 1;
		grid-column-end: 7;
	}

	.exchange__trades {
		grid-column-start: 7;
		grid-column-end: 13;
	}
}

@media screen and (min-width: 992px) {
	.exchange__header--brand {
		grid-column-start: 1;
		grid-column-end: 5;
	}

	.exchange__header--networks {
		grid-column-start: 5;
		grid-column-end: 7;
	}

	.exchange__markets {
		grid-column-start: 2;
		grid-column-end: 6;

		grid-row-start: 1;
		grid-row-end: 3;
	}

	.exchange__orders {
		grid-column-start: 2;
		grid-column-end: 6;

		grid-row-start: 2;
		grid-row-end: 3;
	}

	.exchange__transfers {
		grid-column-start: 7;
		grid-column-end: 12;

		grid-row-start: 1;
		grid-row-end: 3;
	}
}

@media screen and (min-width: 1200px) {
	.exchange__header--brand {
		background: var(--clr-secondary);

	}

	.exchange__section--left {
		padding: 0.25em 2em 0;

		grid-column-start: 1;
		grid-column-end: 5;
	}

	.exchange__section--right {
		grid-column-start: 4;
		grid-column-end: 11;

	}

	.exchange__markets,
	.exchange__transfers,
	.exchange__orders {
		grid-column-start: 1;
		grid-column-end: 13;
	}

	.exchange__markets {
		grid-row: 1;
	}

	.exchange__transfers {
		grid-row: 2;
	}

	.exchange__orders {
		grid-row: 3;
	}
}

@media screen and (min-width: 1400px) {}

@media screen and (min-width: 1600px) {
	.exchange__section--left {
		grid-column-start: 1;
		grid-column-end: 4;
	}

	.exchange__section--right {
		grid-column-start: 4;
		grid-column-end: 13;
	}

	.exchange__header--brand {
		grid-column-start: 1;
		grid-column-end: 4;
	}

	.exchange__header--networks {
		grid-column-start: 4;
		grid-column-end: 5;
	}
}